import { useEffect, useState } from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import { Link, Outlet } from 'react-router-dom';
import TransitionWrapper from './Components/Transitions/TransitionWrapper';
import { FirebaseProvider } from './Context/FirebaseContext';
import * as $ from "jquery";
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

function App() {
  // Add any state that needs to be reset when an error occurs
  const [key, setKey] = useState(0);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const handleError = (error, info) => {
    // Log the error to your error reporting service
    console.error('Error:', error);
    console.error('Error Info:', info);
  };

  const handleReset = () => {
    // Reset all necessary state here
    setKey(prevKey => prevKey + 1); // Force re-mount of child components
    
    // Reset any global state if needed
    // For example, clear localStorage, reset context values, etc.
    // localStorage.clear();
    
    // Reset any third-party services if needed
    // analytics.reset();
    
    // Clear any error states in your state management system
    // dispatch({ type: 'RESET_ERROR_STATE' });
  };

  return (
    <div className="App bg-light">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={handleError}
        onReset={handleReset}
        key={key}
      >
        <HelmetProvider>
          <FirebaseProvider>
            <TransitionWrapper>
              <Outlet />
            </TransitionWrapper>
          </FirebaseProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;