import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { Container, Row, Col, Button, Spinner, Modal } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt, FaPlay, FaDownload } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';
import Pagination from 'react-bootstrap/Pagination';
import HeaderImage from "../images/headers/s2.jpg";
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';
import logo from '../images/pcg.png'; // Adjust the path based on your file structure

const Audio = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { db } = useFirebase();
    const [audioFiles, setAudioFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [lastVisible, setLastVisible] = useState(null);
    const [showPlayer, setShowPlayer] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);
    const filesPerPage = 10;

        // Loading component
    const LoadingSpinner = () => (
        <div 
        style={{ 
            height: '100dvh', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            backgroundColor: '#000',
            gap: '2rem'
        }}
        >
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Logo - Replace src with your actual logo path */}
            <img 
            src={logo}  
            alt="Logo" 
            style={{ 
                width: '60px', 
                height: '60px', 
                objectFit: 'contain',
                position: 'relative',
                zIndex: 2
            }} 
            />
            {/* Spinner positioned absolutely around the logo */}
            <div style={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            width: '100px',
            height: '100px'
            }}>
            <Spinner 
                animation="border" 
                variant="danger"
                style={{ 
                width: '100%', 
                height: '100%',
                borderWidth: '4px',
                opacity: 0.7
                }} 
            />
            </div>
        </div>
        </div>
    );
    useEffect(() => {
        fetchAudioFiles();
    }, [currentPage]);

    const fetchAudioFiles = async () => {
        setLoading(true);
        try {
            let q = query(
                collection(db, 'audioFiles'),
                orderBy('uploadedAt', 'desc'),
                limit(filesPerPage)
            );

            if (lastVisible && currentPage > 1) {
                q = query(q, startAfter(lastVisible));
            }

            const querySnapshot = await getDocs(q);
            const files = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setAudioFiles(files);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (currentPage === 1) {
                const totalFilesQuery = await getDocs(collection(db, 'audioFiles'));
                setTotalPages(Math.ceil(totalFilesQuery.size / filesPerPage));
            }

            setLoading(false);
        } catch (err) {
            console.error("Error fetching audio files: ", err);
            setError("Failed to load audio files. Please try again later.");
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (page === 1) {
            setLastVisible(null);
        }
    };

    const handlePlay = (file) => {
        setCurrentAudio(file);
        setShowPlayer(true);
    };

    const handleDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp.seconds * 1000).toLocaleDateString();
    };

    return (
        <>
            <ScrollToTop />
            <SEO
                title="Audio Files"
                description="Listen to our latest audio files and sermons"
                name='Emmanuel Presbyterian Church.'
                type='audio'
            />
            <NavBarComponent />
            <ClearHeader 
                title='Audio'
                breadcrumbs={['Home', 'Audio']} 
            />
            <Container className='alignleft ' style={{ marginTop: "50px", marginBottom: "50px" }}>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"><LoadingSpinner /></span>
                        </Spinner>
                    </div>
                ) : error ? (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                ) : (
                    <>
                        {audioFiles.map((file) => (
                            <Row key={file.id} className="mb-3 p-3  ">
                                <Col xs={12} md={8}>
                                    <h5>{file.fileName.replace(/\.[^/.]+$/, "")}</h5>
                                    <p><FaCalendarAlt color='var(--redcolor)'/> {formatDate(file.uploadedAt)}</p>
                                </Col>
                                <Col xs={12} md={4} className="d-flex justify-content-end align-items-center">
                                    <Button variant="primary" className="me-2" onClick={() => handlePlay(file)} style={{ backgroundColor:'var(--redcolor)', border:"none"}}>
                                        <FaPlay /> Play
                                    </Button>
                                    <Button variant="secondary" onClick={() => handleDownload(file.downloadURL, file.fileName)}>
                                        <FaDownload /> Download
                                    </Button>
                                </Col>
                            </Row>
                        ))}

                        {totalPages > 1 && (
                            <Pagination className="justify-content-center mt-4">
                                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                
                                {[...Array(totalPages)].map((_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => handlePageChange(index + 1)}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                
                                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                            </Pagination>
                        )}
                    </>
                )}
            </Container>

            <Modal show={showPlayer} onHide={() => setShowPlayer(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{currentAudio?.fileName.replace(/\.[^/.]+$/, "")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <audio controls className="w-100">
                        <source src={currentAudio?.downloadURL} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </Modal.Body>
            </Modal>

            <Footer />
        </>
    );
};

export default Audio;