import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/100-italic.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/200-italic.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/300-italic.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/400-italic.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/500-italic.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/600-italic.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/700-italic.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/800-italic.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/montserrat/900-italic.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/800-italic.css";
import App from './App';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Leadership from './Pages/Leadership';
import GroupPage from './Pages/GroupPage';
import PastLeaders from './Pages/PastLeaders';
import Events from './Pages/Events';
import EventSingle from './Pages/EventSingle';
import Blogs from './Pages/Blogs';
import BlogSingle from './Pages/BlogSingle';
import NotFound from './Pages/NotFound';
import Notices from './Pages/Notices';
import Gallery from './Pages/Gallery';
import Audio from './Pages/Audio';
import KnowUs from './Pages/KnowUs';
import Histroy from './Pages/Histroy';
import ScrollToTop from './Components/ScrollToTop';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'blog',
        element: <Blogs />,
      },
      {
        path: 'blog/:slug/:blogid',
        element: <BlogSingle />,
      },
      {
        path: 'events',
        element: <Events />,
      },
      {
        path: 'events/:slug/:eventid',
        element: <EventSingle />,
      },
      {
        path: 'group/:slug/:groupId',
        element: <GroupPage />,
      },
      {
        path: 'group/:slug/:groupId/pastleaders',
        element: <PastLeaders />,
      },
      {
        path: 'about/leaders',
        element: <Leadership />,
      },
      {
        path: 'about/history',
        element: <Histroy />,
      },
      {
        path: 'about/gettoknowus',
        element: <KnowUs />,
      },
      {
        path: 'media/audiocenter',
        element: <Audio />,
      },
      {
        path: 'media/gallery',
        element: <Gallery />,
      },
      {
        path: 'notices',
        element: <Notices />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} >
        <ScrollToTop />
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
