import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useFirebase } from '../Context/FirebaseContext';
import "react-multi-carousel/lib/styles.css";
import SEO from '../Components/SEO';
import NavBarComponent from '../Components/GeneralNav/Nav';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import HeaderImage from "../images/headers/s2.jpg";
import Footer from '../Components/Footer/Footer';
import ButtonComponent from '../Layouts/IndexLayout/Groups/ButtonComponent';
import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import FetchNameById from '../Components/Helpers/FetchNameById';
import ScrollToTop from '../Components/ScrollToTop';

const PastLeaders = () => {
  const { groupId } = useParams();
  const { db } = useFirebase();
  const [groupData, setGroupData] = useState(null);
  const [tenures, setTenures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leadershipError, setLeadershipError] = useState(false);
  const isHideLogoBadge = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        setLoading(true);

        // Fetch group data
        const groupRef = collection(db, 'groups');
        const groupQuery = query(groupRef, where('id', '==', groupId));
        const groupSnapshot = await getDocs(groupQuery);

        if (!groupSnapshot.empty) {
          setGroupData(groupSnapshot.docs[0].data());
        }

        // Fetch leadership data
        const docRef = doc(db, 'leadership', groupId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const leadershipDoc = docSnap.data();
          const tenuresData = leadershipDoc.tenures;

          if (tenuresData && Object.keys(tenuresData).length > 0) {
            const sortedTenures = Object.entries(tenuresData)
              .sort((a, b) => b[0] - a[0])
              .map(([key, tenure]) => ({
                startTenure: tenure.startTenure,
                endTenure: tenure.endTenure,
                leadership: tenure.leadership,
              }));
            setTenures(sortedTenures);
          } else {
            setLeadershipError(true);
          }
        } else {
          setLeadershipError(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLeadershipError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupData();
  }, [db, groupId]);

  return (
    <>
    <ScrollToTop />
        <SEO
            title={groupData && groupData.name}
            description='The official website of the Emmanuel Presbyterian Church Kasoa'
            name='Group.'
            type='profile' 
        />
        <NavBarComponent />
        <PageHeader 
            title={groupData && groupData.name} 
            backgroundImage={HeaderImage}
        />
        <div style={{height:"40px", width:"100%", backgroundColor: 'var(--redcolor)', position:"relative"}}>
            <p className='truncate-text' style={{textAlign:"left", paddingLeft: "15px", paddingRight:"15px",paddingTop:"10px", color:"whitesmoke", fontWeight:600}}>PAST EXECUTIVES & LEADERS</p>

            {!isHideLogoBadge ? 
            <div style={{position:"relative", top: "-190px", right:"-70%", height:"200px", width:"200px", background:"white",paddingTop:"10px"}}>
            <img src={groupData && groupData.profilePicUrl} alt="group logo" height="180px" width="auto"/>
            </div>
            : ""
            }
        </div>

        <Container fluid className="presbytery-list bg-light text-dark py-5">   
            <div style={{ padding: "20px" }}>
                {loading ? (
                    <p>Loading...</p>
                ) : leadershipError ? (
                    <p>Error fetching leadership data.</p>
                ) : tenures.length > 0 ? (
                tenures.map((tenure, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                    <h4 className='alignleft'>{tenure.startTenure} - {tenure.endTenure}</h4>
                   
                    {tenure.leadership && tenure.leadership.length > 0 ? (
                        <Row className="justify-content-between align-items-left mb-4 ">
                            {tenure.leadership.map((leader, idx) => (
                                <Col sm={6} className='alignleft' >
                                    <p style={{ fontSize:"18px", marginBottom:"5px"}}><strong>{<FetchNameById collectionName={'members'} documentId={leader.memberId}/>}</strong></p>
                                    <p>{leader.position}</p>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>No leadership data available for this tenure.</p>
                    )}
                    
                    </div>
                ))
                ) : (
                <p>No past leadership records found.</p>
                )}
            </div>
        </Container> 

    <Footer/>
    </>
    );
};

export default PastLeaders;

