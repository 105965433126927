
import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronLeft, FaChevronRight, FaPlay, FaPause } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useFirebase } from '../../../Context/FirebaseContext';
import { Spinner } from 'react-bootstrap';
import logo from '../../../images/pcg.png'; // Adjust the path based on your file structure

const ResponsiveCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [carouselData, setCarouselData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { db } = useFirebase();

  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const carouselRef = collection(db, 'carousel');
        const carouselQuery = query(carouselRef, orderBy('id'));
        const querySnapshot = await getDocs(carouselQuery);
        
        const slides = querySnapshot.docs.map(doc => ({
          id: doc.data().id,
          imageUrl: doc.data().imageUrl,
          title: doc.data().title,
          description: doc.data().description,
          buttonText: doc.data().buttonText,
          color: doc.data().color
        }));

        setCarouselData(slides);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching carousel data:", error);
        setLoading(false);
      }
    };

    fetchCarouselData();
  }, [db]);

  const handleSlideChange = useCallback((newIndex, newDirection) => {
    setDirection(newDirection);
    setCurrentSlide(newIndex);
  }, []);

  const nextSlide = useCallback(() => {
    if (carouselData.length === 0) return;
    const newIndex = currentSlide === carouselData.length - 1 ? 0 : currentSlide + 1;
    handleSlideChange(newIndex, 1);
  }, [currentSlide, handleSlideChange, carouselData.length]);

  const prevSlide = useCallback(() => {
    if (carouselData.length === 0) return;
    const newIndex = currentSlide === 0 ? carouselData.length - 1 : currentSlide - 1;
    handleSlideChange(newIndex, -1);
  }, [currentSlide, handleSlideChange, carouselData.length]);

  useEffect(() => {
    let timer;
    if (isAutoPlaying && carouselData.length > 0) {
      timer = setInterval(nextSlide, 5000);
    }
    return () => clearInterval(timer);
  }, [nextSlide, isAutoPlaying, carouselData]);

  // Keep existing animation variants
  const slideVariants = {
    enter: (direction) => ({
      y: direction > 0 ? '100%' : '-100%',
    }),
    center: {
      y: 0,
      transition: {
        y: { type: "tween", duration: 0.8, ease: [0.16, 1, 0.3, 1] }
      }
    },
    exit: (direction) => ({
      y: direction < 0 ? '100%' : '-100%',
      transition: {
        y: { type: "tween", duration: 0.8, ease: [0.16, 1, 0.3, 1] }
      }
    })
  };

  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1]
      }
    })
  };

    // Loading component
const LoadingSpinner = () => (
  <div 
    style={{ 
      height: '100dvh', 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center',
      backgroundColor: '#000',
      gap: '2rem'
    }}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Logo - Replace src with your actual logo path */}
      <img 
        src={logo}  
        alt="Logo" 
        style={{ 
          width: '60px', 
          height: '60px', 
          objectFit: 'contain',
          position: 'relative',
          zIndex: 2
        }} 
      />
      {/* Spinner positioned absolutely around the logo */}
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px'
      }}>
        <Spinner 
          animation="border" 
          variant="danger"
          style={{ 
            width: '100%', 
            height: '100%',
            borderWidth: '4px',
            opacity: 0.7
          }} 
        />
      </div>
    </div>
  </div>
);

  if (loading) {     
    return (       
    <LoadingSpinner />    
      );   
     }

  if (carouselData.length === 0) {
    return (
      <div style={{ 
        height: '100dvh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: '#000',
        color: 'white'
      }}>
        No carousel data available
      </div>
    );
  }

  const CarouselItem = ({ item }) => (
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <img
        src={item.imageUrl}
        alt={item.title}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(45deg, rgba(0,0,0,0.8), rgba(0,0,0,0.4))`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '5% 10%',
          color: 'white',
        }}
      >
        <div style={{ maxWidth: '1200px', margin: '0 auto', width: '100%' }}>
          <motion.span
            custom={0}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(14px, 1.5vw, 18px)',
              color: item.color,
              marginBottom: '1rem',
              display: 'block',
              fontWeight: 500,
            }}
          >
            
          </motion.span>
          <motion.h2
            custom={1}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(2rem, 5vw, 6.25rem)',
              marginBottom: 'clamp(1rem, 2vw, 2rem)',
              fontWeight: 700,
              color: 'white',
              textAlign: 'left',
              lineHeight: 1.1,
            }}
          >
            {item.title}
          </motion.h2>
          <motion.p
            custom={2}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(16px, 1.5vw, 20px)',
              marginBottom: 'clamp(1.5rem, 2.5vw, 3rem)',
              fontWeight: 500,
              maxWidth: '600px',
              color: 'white',
              textAlign: 'left',
              lineHeight: 1.6,
            }}
          >
            {item.description}
          </motion.p>
          <motion.button
            custom={3}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.05, backgroundColor: 'white', color: 'black' }}
            whileTap={{ scale: 0.95 }}
            style={{
              padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
              fontSize: 'clamp(14px, 1vw, 18px)',
              backgroundColor: 'transparent',
              color: 'white',
              border: '2px solid var(--redcolor)',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 600,
              transition: 'all 0.3s ease',
            }}
          >
            {item.buttonText}
          </motion.button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100dvh',
        overflow: 'hidden',
        backgroundColor: '#000',
      }}
    >
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <AnimatePresence initial={false} custom={direction} mode="popLayout">
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <CarouselItem item={carouselData[currentSlide]} />
          </motion.div>
        </AnimatePresence>
      </div>
      
      {/* Navigation Controls */}
      <div style={{
        position: 'absolute',
        bottom: '40px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        zIndex: 2,
      }}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsAutoPlaying(!isAutoPlaying)}
          style={{
            background: 'transparent',
            border: '2px solid white',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'white',
          }}
        >
          {isAutoPlaying ? <FaPause color="var(--redcolor)" size={18} /> : <FaPlay color="var(--redcolor)" size={18} />}
        </motion.button>
        
        <div style={{ display: 'flex', gap: '10px' }}>
          {carouselData.map((_, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.2 }}
              onClick={() => {
                handleSlideChange(index, index > currentSlide ? 1 : -1);
              }}
              style={{
                width: index === currentSlide ? '30px' : '10px',
                height: '10px',
                borderRadius: '5px',
                background: index === currentSlide ? carouselData[currentSlide].color : 'rgba(255,255,255,0.5)',
                border: 'none',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }}
            />
          ))}
        </div>
        
        <div style={{ display: 'flex', gap: '10px' }}>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={prevSlide}
            style={{
              background: 'transparent',
              border: '2px solid white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <FaChevronLeft color="var(--redcolor)" size={18} />
          </motion.button>
          
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={nextSlide}
            style={{
              background: 'transparent',
              border: '2px solid white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <FaChevronRight color="var(--redcolor)" size={18} />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCarousel;
/*import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronLeft, FaChevronRight, FaPlay, FaPause } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';
import SlideOneImage from "../../../images/sliders/s1.png";
import SlideTwoImage from "../../../images/sliders/s2.png";
import SlideThreeImage from "../../../images/sliders/s3.jpg";
import LazyLoad from 'react-lazyload';
import { useFirebase } from '../../../Context/FirebaseContext';

const carouselData = [
  {
    id: 1,
    imageUrl: SlideOneImage,
    title: 'Experience Innovation',
    description: 'Discover groundbreaking solutions that transform the way we live and work.',
    buttonText: 'Learn More',
    color: '#FF6B6B'
  },
  {
    id: 2,
    imageUrl: SlideTwoImage,
    title: 'Craft Your Future',
    description: 'Build the tomorrow you envision with cutting-edge tools and expertise.',
    buttonText: 'Get Started',
    color: '#4ECDC4'
  },
  {
    id: 3,
    imageUrl: SlideThreeImage,
    title: 'Elevate Performance',
    description: 'Unlock your full potential with our advanced performance solutions.',
    buttonText: 'Explore Now',
    color: '#45B7D1'
  }
];

const ResponsiveCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const { db } = useFirebase();

  const handleSlideChange = useCallback((newIndex, newDirection) => {
    setDirection(newDirection);
    setCurrentSlide(newIndex);
  }, []);

  const nextSlide = useCallback(() => {
    const newIndex = currentSlide === carouselData.length - 1 ? 0 : currentSlide + 1;
    handleSlideChange(newIndex, 1);
  }, [currentSlide, handleSlideChange]);

  const prevSlide = useCallback(() => {
    const newIndex = currentSlide === 0 ? carouselData.length - 1 : currentSlide - 1;
    handleSlideChange(newIndex, -1);
  }, [currentSlide, handleSlideChange]);

  useEffect(() => {
    let timer;
    if (isAutoPlaying) {
      timer = setInterval(nextSlide, 5000);
    }
    return () => clearInterval(timer);
  }, [nextSlide, isAutoPlaying]);

  const slideVariants = {
    enter: (direction) => ({
      y: direction > 0 ? '100%' : '-100%',
    }),
    center: {
      y: 0,
      transition: {
        y: { type: "tween", duration: 0.8, ease: [0.16, 1, 0.3, 1] }
      }
    },
    exit: (direction) => ({
      y: direction < 0 ? '100%' : '-100%',
      transition: {
        y: { type: "tween", duration: 0.8, ease: [0.16, 1, 0.3, 1] }
      }
    })
  };


  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1]
      }
    })
  };

  const CarouselItem = ({ item }) => (
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      
      <img
        src={item.imageUrl}
        alt={item.title}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(45deg, rgba(0,0,0,0.8), rgba(0,0,0,0.4))`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '5% 10%',
          color: 'white',
        }}
      >
        <div style={{ maxWidth: '1200px', margin: '0 auto', width: '100%' }}>
          <motion.span
            custom={0}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(14px, 1.5vw, 18px)',
              color: item.color,
              marginBottom: '1rem',
              display: 'block',
              fontWeight: 500,
            }}
          >
            {`0${item.id}/0${carouselData.length}`}
          </motion.span>
          <motion.h2
            custom={1}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(2rem, 5vw, 6.25rem)',
              marginBottom: 'clamp(1rem, 2vw, 2rem)',
              fontWeight: 700,
              color: 'white',
              textAlign: 'left',
              lineHeight: 1.1,
            }}
          >
            {item.title}
          </motion.h2>
          <motion.p
            custom={2}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            style={{
              fontSize: 'clamp(16px, 1.5vw, 20px)',
              marginBottom: 'clamp(1.5rem, 2.5vw, 3rem)',
              fontWeight: 500,
              maxWidth: '600px',
              color: 'white',
              textAlign: 'left',
              lineHeight: 1.6,
            }}
          >
            {item.description}
          </motion.p>
          <motion.button
            custom={3}
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.05, backgroundColor: 'white', color: 'black' }}
            whileTap={{ scale: 0.95 }}
            style={{
              padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
              fontSize: 'clamp(14px, 1vw, 18px)',
              backgroundColor: 'transparent',
              color: 'white',
              border: '2px solid var(--redcolor)',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 600,
              transition: 'all 0.3s ease',
            }}
          >
            {item.buttonText}
          </motion.button>
        </div>
      </div>
    </div>
  );

  return (
    <div
    style={{
      position: 'relative',
      width: '100%',
      height: '100dvh',
      overflow: 'hidden',
      backgroundColor: '#000',
    }}
  >
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <AnimatePresence initial={false} custom={direction} mode="popLayout">
        <motion.div
          key={currentSlide}
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <CarouselItem item={carouselData[currentSlide]} />
        </motion.div>
      </AnimatePresence>
    </div>
      
      
      <div style={{
        position: 'absolute',
        bottom: '40px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        zIndex: 2,
      }}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsAutoPlaying(!isAutoPlaying)}
          style={{
            background: 'transparent',
            border: '2px solid white',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'white',
          }}
        >
          {isAutoPlaying ? <FaPause color="var(--redcolor)" size={18} /> : <FaPlay color="var(--redcolor)" size={18} />}
        </motion.button>
        
        <div style={{ display: 'flex', gap: '10px' }}>
          {carouselData.map((_, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.2 }}
              onClick={() => {
                handleSlideChange(index, index > currentSlide ? 1 : -1);
              }}
              style={{
                width: index === currentSlide ? '30px' : '10px',
                height: '10px',
                borderRadius: '5px',
                background: index === currentSlide ? carouselData[currentSlide].color : 'rgba(255,255,255,0.5)',
                border: 'none',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }}
            />
          ))}
        </div>
        
        <div style={{ display: 'flex', gap: '10px' }}>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={prevSlide}
            style={{
              background: 'transparent',
              border: '2px solid white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <FaChevronLeft color="var(--redcolor)" size={18} />
          </motion.button>
          
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={nextSlide}
            style={{
              background: 'transparent',
              border: '2px solid white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <FaChevronRight color="var(--redcolor)" size={18} />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCarousel;
*/