import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import HeaderImage from "../images/headers/s2.jpg"
import WelcomeSection from '../Layouts/About/WelcomeSection';
import VisionMissionBeliefs from '../Layouts/About/VisionMissionBeliefs';
import ScrollToTop from '../Components/ScrollToTop';

const KnowUS = () => {
  return (
    <>
    <ScrollToTop />
        <SEO
            title='Know Us'
            description='The official website of the Emmanuel Presbyterian Church Kasoa'
            name='Emmnuel Presbyterian Church.'
            type='profile' 
        />
        <NavBarComponent />
        <ClearHeader 
            title="Get To Know Us" 
            breadcrumbs={['Home', 'Know Us']} 
        />
        <Container style={{marginTop:"60px"}}>
            <WelcomeSection />
            <VisionMissionBeliefs />
        </Container>
      
      <Footer />
    </>
  );
};

export default KnowUS;