import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaXTwitter, FaSquareInstagram  } from "react-icons/fa6";
import { useFirebase } from '../../Context/FirebaseContext';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import SocialIcons from './SocialIcons';
import BlogPost from './BlogPost';
import ImageOne from "../../images/footer/image_1.jpg";
import ImageTwo from "../../images/footer/image_2.jpg";
import QuickLinks from './QuickLinks';
import ContactInfo from './ContactInfo';


const Footer = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { db } = useFirebase();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentPosts = async () => {
        try {
            const blogCollection = collection(db, 'blog');
            const recentPostsQuery = query(
                blogCollection,
                where('status', '==', 'publish'),
                orderBy('createdOn', 'desc'),
                limit(3)
            );

            const querySnapshot = await getDocs(recentPostsQuery);
            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecentPosts(posts);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching recent posts:', err);
            setError(err);
            setLoading(false);
        }
    };

      fetchRecentPosts();
  }, [db]);

  const footerLinks = [
    { text: 'HOME', href: '/' },
    { text: 'ABOUT US', href: 'gettoknowus' },
    { text: 'EVENTS', href: '/events' },
    { text: 'BLOGS', href: '/blog' },
    { text: 'CONTACT', href: '/contact' },
    { text: 'NOTICES', href: '/notices' },
  ];

  const createSlug = (string) => {
    string = string.toLowerCase();
    string = string.replace(/[^a-z0-9 ]/g, '');
    string = string.replace(/\s+/g, '-');
    string = string.replace(/^-|-$/g, '');
    return string === '' ? 'slug' : string;
  };

  const renderAuthorName = (author) => {
    if (typeof author === 'string') {
      return author;
    }
    return author?.name || 'Unknown Author';
  };

  return (
    <footer className="bg-dark text-white py-3" style={{paddingTop:"40px", paddingLeft:"30px", paddingRight:"30px"}}>
      <Container fluid>
        <div style={{ paddingBottom: "20px", paddingTop:"15px", marginTop:"70px", paddingBottom:"50px", overflow:"hidden"}}>
          <Row>
              <Col xs={12} md={3} style={{paddingBottom:"15px"}}>
                  <p className='footer-heading'>EMMANUEL DIST. CONG.</p>
                  <p style={{textAlign:"left", color:"rgba(255, 255, 255, 0.7)"}}>
                  Welcome to our church, where faith and family come together.
We're a community of believers passionate about sharing God's love, growing in faith, and serving others. Explore our site to learn more about our mission, services, and ministries
                  </p>
                  <SocialIcons/>
              </Col>
              <Col xs={12} md={3}>
                  <p className='footer-heading'>Lastest News</p>
                  <div>
                    {loading ? (
                      <p style={{color: "rgba(255, 255, 255, 0.7)"}}>Loading latest posts...</p>
                    ) : (
                      recentPosts.map((post, index) => (
                        <BlogPost 
                          key={index}
                          imageUrl={post.imageUrl ? post.imageUrl :'https://firebasestorage.googleapis.com/v0/b/presby-af279.appspot.com/o/placeholders%2Fpost-placeholder.jpg?alt=media&token=316916e1-dcca-44b6-b9b3-2a510dc3d530'}
                          href={`/blog/${createSlug(post.title)}/${post.id}`}
                          title={post.title}
                          date={post.date}
                          author={renderAuthorName(post.author)}
                        />
                      ))
                    )}
                  </div>
              </Col>
              <Col xs={12} md={3}>
                <p className='footer-heading'>Quick Links</p>
                <QuickLinks links={footerLinks} />
                 
              </Col>
              <Col xs={12} md={3}>
                  <p className='footer-heading truncated-text'>HAVE A QUESTIONS?</p>
                
                  <ContactInfo /> 
              </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12} md={6}>
            {/* Your left-side content, like copyright notice */}
            <p style={{textAlign:"left", color:"rgba(255, 255, 255, 0.7)"}}>&copy; 2024 WILBELANN </p>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-end">
            {/* Your right-side content, like social media links */}
            <a href="#" className="text-white mx-2">
              <i className="bi bi-facebook" />
            </a>
            <a href="#" className="text-white mx-2">
              <i className="bi bi-twitter" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
