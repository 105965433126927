import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';
import logo from './images/pcg.png'; // Adjust the path based on your file structure

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const handleReset = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
      <Row className="justify-content-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="border-0 shadow-sm">
            <Card.Body className="text-center p-5">
              <Image 
                src={logo} 
                alt="Logo" 
                className="mb-4" 
                style={{ width: '120px', height: 'auto' }}
              />
              
              <h1 className="mb-4">Oops! Something went wrong</h1>
              
              <p className="text-muted mb-4">
                We apologize for the inconvenience. An error has occurred in the application.
              </p>
              
              <Card className="bg-light border-0 mb-4">
                <Card.Body>
                  <pre className="text-danger mb-0" style={{ overflowX: 'auto' }}>
                    {error.message}
                  </pre>
                </Card.Body>
              </Card>
              
              <div className="d-flex gap-3 justify-content-center">
                <Button 
                  variant="primary" 
                  onClick={handleReset}
                >
                  Return Home
                </Button>
                <Button 
                  variant="outline-primary" 
                  onClick={resetErrorBoundary}
                >
                  Try Again
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorFallback;