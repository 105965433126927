import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import ScrollToTop from '../Components/ScrollToTop';

const About = () => {
  return (
    <>
      <ScrollToTop />
      <NavBarComponent />
      <Container style={{marginTop:"60px"}}>
        <h2>About</h2>
        <p>This is the About page.</p>
      </Container>
      
      <Footer />
    </>
  );
};

export default About;