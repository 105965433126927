import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EventListing from './EventListing';
import BlogPost from './BlogPost';
import { useFirebase } from '../../../Context/FirebaseContext';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import SlugGenerator from '../../../Components/Helpers/SlugGenerator';
import WeHelpSection from './WeHelpSection';

const BlogUpdate = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [recentPosts, setRecentPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const { db } = useFirebase();

    useEffect(() => {
        const fetchRecentPosts = async () => {
            try {
                const blogCollection = collection(db, 'blog');
                const recentPostsQuery = query(
                    blogCollection,
                    where('status', '==', 'publish'),
                    orderBy('createdOn', 'desc'),
                    limit(3)
                );

                const querySnapshot = await getDocs(recentPostsQuery);
                const posts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setRecentPosts(posts);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching recent posts:', err);
                setError(err);
                setLoading(false);
            }
        };

        fetchRecentPosts();
    }, [db]);

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            try {
                const today = new Date();
                const dateString = today.toISOString().split('T')[0];

                const eventsCollection = collection(db, 'events');
                const eventsQuery = query(
                    eventsCollection,
                    where('status', '==', 'active'),
                    where('endDate', '>=', dateString),
                    orderBy('endDate', 'asc'),
                    orderBy('endTime', 'asc'),
                    limit(3)
                );

                const querySnapshot = await getDocs(eventsQuery);
                const events = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setUpcomingEvents(events);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching upcoming events:', err);
                setError(err);
                setLoading(false);
            }
        };

        fetchUpcomingEvents();
    }, [db]);

    const createSlug = (string) => {
        string = string.toLowerCase();
        string = string.replace(/[^a-z0-9 ]/g, '');
        string = string.replace(/\s+/g, '-');
        string = string.replace(/^-|-$/g, '');
        return string === '' ? 'slug' : string;
    };

    const renderAuthorName = (author) => {
        if (typeof author === 'string') {
            return author;
        }
        return author.name || 'Unknown Author';
    };

    const EmptyState = ({ message }) => (
        <div className="empty-state">
            <p className="text-muted">{message}</p>
        </div>
    );

    if (loading) {
        return (
            <Container>
                <Row style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                    <Col xs={12}><h1>Loading...</h1></Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Row style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                {recentPosts.length !== 0 && upcomingEvents.length !== 0  && <Col xs={12} sm={12}><h1 style={{ textAlign: "left", marginBottom: "50px" }}>Blog & Events Updates</h1></Col>}
                
                {recentPosts.length === 0 && upcomingEvents.length === 0 ? (
                    <Col xs={12}>
                        <WeHelpSection />
                    </Col>
                ) : (
                    <>
                        <Col xs={12} sm={12} md={8} style={{ overflow: "hidden", paddingRight: "30px", paddingBottom: "50px" }} className='truncated-text'>
                            <h3 className='small-heading'>Latest Blogs</h3>
                            {recentPosts.length > 0 ? (
                                recentPosts.map((item, index) => (
                                    <BlogPost 
                                        key={index}
                                        imageUrl={item.imageUrl !== ''? item.imageUrl :'https://firebasestorage.googleapis.com/v0/b/presby-af279.appspot.com/o/placeholders%2Fpost-placeholder.jpg?alt=media&token=316916e1-dcca-44b6-b9b3-2a510dc3d530'}
                                        href={`/blog/${createSlug(item.title)}/${item.id}`}
                                        alt={<SlugGenerator title={item.title} />}
                                        title={item.title}
                                        date={item.date}
                                        author={renderAuthorName(item.author)}
                                    />
                                ))
                            ) : (
                                <EmptyState message="No blog posts available at the moment. New content coming soon!" />
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                            <h3 className='small-heading'>Events</h3>
                            {upcomingEvents.length > 0 ? (
                                upcomingEvents.map((item, index) => (
                                    <EventListing 
                                        key={index}
                                        month={item.startDate}
                                        day={item.startDate}
                                        title={item.eventName}
                                        href={`/events/${createSlug(item.eventName)}/${item.id}`}
                                        startDate={item.startDate}
                                        endDate={item.endDate}
                                    />
                                ))
                            ) : (
                                <EmptyState message="No upcoming events scheduled. Stay tuned for future events!" />
                            )}
                        </Col>
                    </>
                )}
            </Row>
        </Container>
    );
};

export default BlogUpdate;